@import "src/styles/utils";

.page {
  width: 100%;
  height: 100%;
  margin: auto;
}

.flipCoinWrapper {
  @include flex-centered(true, false);
  justify-content: space-evenly;
  flex: 1;
  max-height: 792px;
  padding-top: $page-top-padding-mobile;

  @include is-tablet-portrait {
    padding-top: $page-top-padding-tablet;
  }
}

.headingBox {
  @include flex-centered(true, true);
  text-align: center;
  width: 100%;
  z-index: 2;

  .heading {
    @include h2;
    margin: 0;
  }
}

.questionCounter {
  @include h2;
  text-transform: none;
  color: $light-text-color;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;

  @include is-tablet-portrait {
    margin-top: 30px;
  }
}

.subHeading {
  @include sub-heading(false);
  width: 100%;
  margin-top: 0.5rem;
}

.coinFlipBox {
  @include flex-centered(true, true);
  margin-bottom: 1rem;

  .coinFlipText {
    @include h2;
    color: $light-text-color;
    text-align: center;
    margin-bottom: 10px;
    opacity: 0;
    transition: opacity 0.2s ease-out;
    z-index: 2;

    &.isFlipped {
      opacity: 1;
    }

    @media (max-height: 670px) {
      margin-top: 0;
    }
  }

  img {
    cursor: pointer;
    width: 120px;
    height: 120px;
    z-index: 2;

    @include is-tablet-portrait {
      width: 150px;
      height: 150px;
    }
  }

  @keyframes rotate {
    to {
      transform: rotate(1turn);
    }
  }

  .lightRays {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(#fff, black);
    overflow: hidden;
    --a: rgba(13, 10, 0, 0.9);
    --b: rgba(13, 10, 0, 1);
    --c: rgba(13, 10, 0, 0.92);
    --d: rgba(13, 10, 0, 0.98);
    z-index: 0;
    opacity: 0;
    transition: all 0.5s ease-out;

    &.showGlow {
        opacity: 1;
    }
  }

  .lightRays:before,
  .lightRays:after {
    content: "";
    position: absolute;
    top: 35%;
    left: 50%;
    margin: -100vmax;
    width: 200vmax;
    height: 200vmax;
    opacity: 0.8;
    mix-blend-mode: multiply;

    @include is-tablet-portrait {
      top: 45%;
    }
  }

  .lightRays:before {
    background: conic-gradient(
                    var(--a),
                    var(--b),
                    var(--c),
                    var(--d),
                    var(--a),
                    var(--d),
                    var(--a),
                    var(--d),
                    var(--b),
                    var(--c),
                    var(--d),
                    var(--a),
                    var(--b)
    );
    animation: 8s cubic-bezier(0.785, 0.135, 0.150, 0.860) infinite rotate;
  }
  .lightRays:after {
    background: conic-gradient(
                    var(--c),
                    transparent,
                    var(--c),
                    var(--a),
                    transparent,
                    var(--b),
                    var(--c),
                    transparent,
                    var(--c),
                    var(--a)
    );
    animation: 5s cubic-bezier(0.445, 0.050, 0.550, 0.950) 1s infinite reverse rotate;
  }
}

.userCoinsBox {
  @include flex-centered(false, true);
  margin-top: 1.5rem;
  flex-wrap: wrap;

  width: 100%;
  max-width: 335px;
  height: 134px;
  margin-bottom: calc(20px - 6px);

  @media (max-width: 335px) and (min-height: 650px) {
    margin-bottom: 0;
  }

  @media (max-height: 670px) {
    margin: 0;
  }

  @media (min-width: 335px) and (min-height: 650px) {
    width: 100%;
    max-width: 335px;
    height: calc(156px + 6px * 2);
    margin-bottom: 0;
  }

  @include is-tablet-portrait {
    margin-top: 4%;
  }

  @include is-desktop {
    margin-top: 2%;
  }
}

.bottomBlock {
  @include flex-centered(true, true);
  width: 100%;
  flex: 0 0 56px;
  margin-bottom: 2rem;
  z-index: 2;

  .paragraph {
    @include sub-heading(false);
    text-align: center;
  }

  button {
    width: 85%;
    max-width: 430px;
    text-transform: uppercase;

    @include is-desktop {
      width: 80%;
    }

  }

  @include is-tablet-portrait {
    margin-top: 3.5rem;
  }

  @include is-desktop {
    margin-top: 2rem;
  }
}
