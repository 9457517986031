@import '../../styles/utils';

.wrapper {
  z-index: 10;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 4rem;

  pointer-events: none;

  a,
  button {
    pointer-events: auto;
  }

  :last-child {
    right: 1rem;
  }

  a {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  @include is-tablet-portrait {
    :last-child {
      right: 3rem;
    }
  }
}

.hamburgerButtonBox {
  position: relative;
  left: 0.9rem;

  @include is-tablet-portrait {
      left: 2.5rem;
  }
}

.logoContainer {
  cursor: pointer;

  .isStartView {
    position: relative;
    width: 186px;
    height: 76px;
    top: 100px;
    left: 0;
  }
}

.logo {
  width: 93px;
  height: 38px;
  transition: all 0.4s linear;

  img {
    width: 100%;
    height: 100%;
  }
}

.balanceWrapper {
  display: flex;
  flex-direction: row;
  position: relative;
}

.innerWrapper {
  @include flex-centered(true, false);
  align-items: flex-end;

  .balanceLabel {
    color: $primary-color;
    margin: 0;
    font-size: 10px;
    font-weight: 600;

    @include is-tablet-portrait {
      font-size: 12px;
    }
  }

  .balance {
    @include sub-heading(true);
    margin: 0;
    text-align: end;
    line-height: 17px;
  }
}



