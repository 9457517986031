@import "../../styles/utils";

.menuBox {
  background-color: $color-black;
  z-index: 20;
  height: 100vh;
  left: 0;
  top: 0;
  position: absolute;

  :nth-child(1) {
    left: 80%;
    top: 10px;
  }

  @media (max-height: $screen-mobile-portrait) and (orientation: landscape) {
    height: unset;
    overflow: auto;
  }
}

.menuItems {
  @include flex-centered(true, false);
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 1rem;
  margin-left: 2rem;

  font-size: 15px;
  font-family: $root-font-family;
  font-weight: 600;
}

.menuItem {
  cursor: pointer;
  text-decoration: none;
  color: $light-text-color;
  padding: 15px 0 15px 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: $primary-color;
  }
}

.soundButtonContainer {
  position: relative;
  @include flex-centered(true, false);
  justify-content: flex-start;
}

.soundButton {
  font-size: 15px !important;
  font-family: $root-font-family;
  font-weight: 600 !important;
  left: 0 !important;
  top: -5px !important;
  text-transform: uppercase;
  justify-content: flex-start;

  &:hover {
    color: $primary-color;
  }
}