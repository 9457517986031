@import "../../styles/index.scss";

.page {
  @include flex-centered(true, false);
  justify-content: flex-start;
  margin: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  padding-top: $page-top-padding-mobile;
  min-height: 100vh;
  background-image: url("../../assets/background/bg-desktop.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @include is-tablet-portrait {
    background-image: url("../../assets/background/bg-desktop.jpg");
    padding-top: $page-top-padding-tablet;
  }

  @media (max-height: $screen-mobile-portrait) and (orientation: landscape) {
    padding-top: 3rem;
    background-position: 10% 20%;
  }
}

.headingBox {
  @include flex-centered(true, true);
  text-align: center;
  width: 85%;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  margin-top: 100px;
}

.heading {
  @include h2;
  font-size: 25px;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.subHeading {
  @include sub-heading(true);
  font-size: 18px;
  letter-spacing: 1px;
  width: 100%;
}